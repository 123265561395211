import React, { useEffect, useState } from "react";
import * as SwotService from "../../services/SwotService";
import { Card } from "antd";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {
    SwotSurveyChart,
    ResponseSurveyChart,
} from "../../Helpers/dashboardsCharts";
import SwotLineChart from "../../Helpers/SwotLineChart";
import {
    Department,
    Schedule,
    Swot,
} from '../../constant/icons';
import { Spin } from "antd";

export default function Dashboard() {
    const [dashboardData, setDashboardData] = useState();
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        SwotService.getDashboardData().then((response) => {
            setDashboardData(response);
            setLoader(false);
        });
    }, []);

    const items = [
        {
            key: 1,
            label: "Total Swot Schedule",
            icon: <Schedule />,
            value: dashboardData?.total_data?.swot_schedule,
        },
        {
            key: 2,
            label: "Total Swot",
            icon: <Swot />,
            value: dashboardData?.total_data?.swot,
        },
        {
            key: 3,
            label: "Total Response",
            icon: <Department />,
            value:dashboardData?.total_data?.swotData?.totalResponse ==0?"0 %":
                (
                    (parseInt(dashboardData?.total_data?.swotData?.totalResponse) /
                        parseInt(dashboardData?.total_data?.swotData?.totalEmployee)) *
                    100
                ).toFixed(2) + " %",
        },
        // {
        //   key: 4,
        //   label: "Total Survey",
        //   icon: <CalendarOutlined />,
        //   value: dashboardData?.total_data?.survey,
        // },
        // {
        //   key: 5,
        //   label: "Total Survey Response",
        //   icon: <CalendarOutlined />,
        //   value:
        //     ((parseInt(dashboardData?.total_data?.surveyData?.totalResponse) /
        //       parseInt(dashboardData?.total_data?.surveyData?.totalEmployee)) *
        //       100 || 0) + "%",
        // },
    ];

    return (
        <>
            {loader ? (
                <div className="spinner">
                    <Spin />
                </div>
            ) : (
                <div className="mainWrapper">
                    <Card className="card contentCard border-0 pb-5">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="pageHeader">
                                    <h3>Dashboard</h3>
                                </div>
                            </div>
                            {items?.map((item, index) => (
                                <div className="col-md-4 mb-4" key={index}>
                                    <Card className="dashboardCard">
                                        <div className="innerBody">
                                            <div className="leftSection">
                                                <h5>{item.label}</h5>
                                                <span>{item.value}</span>
                                            </div>
                                            <div className="rightSection">
                                                <div className="iconWrapper">{item.icon}</div>
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                            ))}

                            <div className="col-lg-6 mb-4">
                                <Card className="chartCard">
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={SwotSurveyChart(dashboardData?.monthlyData)}
                                    />
                                </Card>
                            </div>
                            <div className="col-lg-6 mb-4">
                                <Card className="chartCard">
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={ResponseSurveyChart(dashboardData?.monthlyData)}
                                    />
                                </Card>
                            </div>
                            <div className="col-lg-6">
                                <Card className="chartCard">
                                    <SwotLineChart></SwotLineChart>
                                </Card>
                            </div>
                        </div>
                    </Card>
                </div>
            )}
        </>
    );
}
