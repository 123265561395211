import React, { useEffect, useState } from "react";
import { Card } from "antd";
import "../styles/myform.css";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as AuthService from "../services/AuthService";

const VerifyEmail = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  let navigate = useNavigate();
  const [status, setStatus] = useState(true);
  const token = queryParameters.get("token");
  useEffect(() => {
    AuthService.verifyEmail(token)
      .then(() => {
        setStatus(true);
       
        Swal.fire({
            title: "Success",
            text: "Verification Done",
            icon: "Success",
          }).then(()=>{
            navigate("/");
          });
      })
      .catch((err) => {
        let message = err?.response?.data?.message;
        Swal.fire({
          title: "Oops...",
          text: message,
          icon: "error",
        }).then(()=>{
            navigate("/");
          });;
      });
  }, []);


  return (
    <>
      {" "}
      {status ? (
        <Card>
            <div className="alreadySubmittedWrapper"><h5>Email Verifiaction Done.</h5></div>
        </Card>
      ) : (
        <div className="alreadySubmittedWrapper">
          <Card>
            <h5>Email Verifiaction Failed.</h5>
          </Card>
        </div>
      )}
    </>
  );
};
export default VerifyEmail;
