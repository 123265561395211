import React, { useEffect, useState } from "react";
import { Table, Button, theme, Modal } from "antd";
import * as SwotService from "../../services/SwotService";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";
import moment from "moment";

const EmployeeSwotList = () => {
    const [loader, setLoader] = useState(true);
    const [swots, setSwots] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [employeeName, setEmployeeName] = useState()
    const [FilterSWOTResponse, setFilterSWOTResponse] = useState();

    const showModal = (recordID, id) => {
        getSwotResponse(id, recordID);
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
        setFilterSWOTResponse()
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setFilterSWOTResponse()
    };

    const { state } = useLocation();
    const employeeId = state?.id;

    const getSwots = () => {
        SwotService.getSwotByEmoloyeeID(employeeId)
            .then((response) => {
                setSwots(response.swots);
                setLoader(false);
            })
            .catch((err) => {
                let message = err.response.data.error;
                Swal.fire({
                    title: "Oops...",
                    text: message,
                    icon: "error",
                });
                console.log({ err });
            });
    };

    const getSwotResponse = (employeeId, recordID) => {
        SwotService.getSwotResponseByEmoloyeeID(employeeId)
            .then((response) => {
                setEmployeeName(response?.employee_name)
                const filterData = response?.swot_responses?.filter(
                    (record) => record.swot_id === recordID
                );
                setFilterSWOTResponse(filterData);
                setSwots(response.swot_responses);
                setLoader(false);
            })
            .catch((err) => {
                let message = err.response.data.error;
                Swal.fire({
                    title: "Oops...",
                    text: message,
                    icon: "error",
                });
                console.log({ err });
            });
    };


    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            render: (text, record) => (
                <>
                    <span>{record.id ? record.id : record?.swot_id}</span>
                </>
            ),
        },
        {
            title: "Swot Name",
            dataIndex: "name",
            key: "name",
            render: (text, record) => (
                <>
                    <span>{record.name ? record.name : record?.swot_name}</span>
                </>
            ),
        },
        {
            title: "Is Filled",
            key: "is_filled",
            render: (text, record) => (
                <span>{record.is_filled === true ? "True" : "False"}</span>
            ),
        },
        {
            title: "Updated At",
            key: "updated_at",
            render: (text, record) => (
                <span>
                    {moment(record.updated_at).format("MMMM Do YYYY, h:mm:ss a")}
                </span>
            ),
        },
        {
            title: "Actions",
            key: "actions",
            render: (text, record) => (
                <span>{record.is_filled === true ?
                    <Button
                        onClick={() =>
                            showModal(record.id ? record.id : record?.swot_id, state?.id)
                        }
                    >
                        View
                    </Button> : "Not Filled"}
                </span>
            ),
        },
    ];

    const swotResponseColumn = [
        {
            title: "strengths",
            dataIndex: "strengths",
            key: "strengths",
            render: (text, record) => (
                <div className="strenthsTableRow">
                    <p className="wordBreak">{record?.strengths}</p>
                </div>
            ),
        },
        {
            title: "weaknesses",
            dataIndex: "weaknesses",
            key: "weaknesses",
            render: (text, record) => (
                <div className="strenthsTableRow">{record?.weaknesses}</div>
            ),
        },
        {
            title: "opportunities",
            dataIndex: "opportunities",
            key: "opportunities",
            render: (text, record) => (
                <div className="strenthsTableRow">{record?.opportunities}</div>
            ),
        },
        {
            title: "threats",
            dataIndex: "threats",
            key: "threats",
            render: (text, record) => (
                <div className="strenthsTableRow">{record?.threats}</div>
            ),
        },
    ];

    useEffect(() => {
        if (employeeId) {
            getSwots(employeeId);
        }
    }, [employeeId]);
    return (
        <>
            <div
                style={{
                    padding: 24,
                    textAlign: "center",
                    background: colorBgContainer,
                    borderRadius: borderRadiusLG,
                }}
            >
                <div className="createComponent">
                    <h5>Swot List</h5>
                </div>
            </div>
            <Table
                dataSource={swots}
                columns={columns}
                rowKey="id"
                loading={loader}
            />
            <Modal
                title={
                    <>
                        <h3>Swot Response</h3>
                        <p className="employee-name">{employeeName}</p>
                    </>
                }
                open={isModalOpen}
                width={991}
                onOk={handleOk}
                onCancel={handleCancel}
                centered
            >
                <Table
                    dataSource={FilterSWOTResponse}
                    columns={swotResponseColumn}
                    rowKey="id"
                    pagination={{
                        showSizeChanger: true,
                    }}
                    sortDirections={["descend", "ascend", "descend"]}
                    defaultSortOrder={"descend"}
                />
            </Modal>
        </>
    );
};

export default EmployeeSwotList;
