// CreateDepartment.js

import React from "react";
import { Form, Input, Button, Card } from "antd";
import Swal from "sweetalert2";
import * as Department from "../../services/Department";
import { useLocation, useNavigate } from "react-router-dom";

const CreateDepartment = () => {
    let navigate = useNavigate();
    const { state } = useLocation();
    const [form] = Form.useForm();
    const department = state?.department ? state?.department : {};

    const onFinish = (values) => {
        if (state?.department) {
            console.info(values);
            Department.updateDepartment(values, state?.department.id)
                .then((response) => {
                    Swal.fire({
                        title: "Department Updated",
                        icon: "success",
                    }).then(function () {
                        navigate("/department");
                    });
                })
                .catch((err) => {
                    let message = err.response.data.error.name;
                    Swal.fire({
                        title: "Oops...",
                        text: message,
                        icon: "error",
                    });
                    console.log({ err });
                });
        } else {
            Department.createDepartmnet(values)
                .then((response) => {
                    Swal.fire({
                        title: "Department Created",
                        icon: "success",
                    }).then(function () {
                        navigate("/department");
                    });
                })
                .catch((err) => {
                    let message = err.response.data.error.name;
                    Swal.fire({
                        title: "Oops...",
                        text: message,
                        icon: "error",
                    });
                    console.log({ err });
                });
        }

        form.resetFields();

    };

    return (
        <div className="mainWrapper">
            <Card className="card contentCard border-0 pb-5">
                <div className="row">
                    <div className="col-md-12">
                        <div className="pageHeader">
                            <h3>Create Department</h3>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <Form form={form} onFinish={onFinish} className="departmentForm">
                            <div className="formItem">
                                <label>Department Name</label>
                                <Form.Item
                                    initialValue={department.name ? department.name : ""}
                                    name="name"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input the department name!",
                                        },
                                    ]}
                                >
                                    <Input placeholder="Enter Department Name" />
                                </Form.Item>
                                <div className="submitSection">
                                    <Button type="primary" htmlType="submit">
                                        Submit
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </Card>
        </div>
        // <div className="scheduleSwotWrapper">
        //     <Card className="formCard">
        //         <div className="formheader">
        //             <h3>Department Form</h3>
        //         </div>
        //         <Form form={form} onFinish={onFinish} className="departmentForm">
        //             <div className="formItem">
        //                 <label>Department Name</label>
        //                 <Form.Item
        //                     initialValue={department.name ? department.name : ""}
        //                     name="name"
        //                     rules={[
        //                         {
        //                             required: true,
        //                             message: "Please input the department name!",
        //                         },
        //                     ]}
        //                 >
        //                     <Input placeholder="Enter Department Name" />
        //                 </Form.Item>
        //                 <div className="submitSection">
        //                     <Form.Item>
        //                         <Button type="primary" htmlType="submit">
        //                             Submit
        //                         </Button>
        //                     </Form.Item>
        //                 </div>
        //             </div>
        //         </Form>
        //     </Card>
        // </div>
    );
};

export default CreateDepartment;
