import Http from "../Http";
import { backendUrl } from "../helper";

export const createSwot = (data) => {
  return new Promise((resolve, reject) => {
    Http.post(backendUrl + "swot", data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const scheduleSwot = (data) => {
  return new Promise((resolve, reject) => {
    Http.post(backendUrl + "schedule_swot", data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const swotResponse = (data,token) => {
  return new Promise((resolve, reject) => {
    Http.post(backendUrl + `swot/response?token=${token}`, data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getSwotResponse = () => {
  return new Promise((resolve, reject) => {
    Http.get(backendUrl + "swot/response")
      .then((res) => {
        resolve(res.data.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getSwotSchedules = (page,filter) => {
  return new Promise((resolve, reject) => {
    Http.get(backendUrl + `schedule_swot?filters=${filter}`, {
      params: page,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getSwotScheduleById = (id) => {
  return new Promise((resolve, reject) => {
    Http.get(backendUrl + `schedule_swot/${id}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteSwotSchedules = (id) => {
  return new Promise((resolve, reject) => {
    Http.delete(backendUrl + `schedule_swot/${id}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updatescheduleSwot = (data,id) => {
  return new Promise((resolve, reject) => {
    Http.put(backendUrl + `schedule_swot/${id}`, data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const checkSwotExpiration = (token) => {
  return new Promise((resolve, reject) => {
    Http.get(backendUrl + `swot/check_expiration?token=${token}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getSwotSummary = (page) => {
  return new Promise((resolve, reject) => {
    Http.get(backendUrl + `getSwotSummary`, {
      params: page,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getSwotResponseBySwotId = (id) => {
  return new Promise((resolve, reject) => {
    Http.get(backendUrl + `swot_responses?swotId=${id}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getSwotByEmoloyeeID= (id) => {
  return new Promise((resolve, reject) => {
    Http.get(backendUrl + `swotByEmployee?employee_id=${id}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getSwotResponseByEmoloyeeID = (id) => {
  return new Promise((resolve, reject) => {
    Http.get(backendUrl + `swot_and_survey_responses?employee_id=${id}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getDashboardData = (id) => {
  return new Promise((resolve, reject) => {
    Http.get(backendUrl + "dashboard")
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const improtSwotResponseCSV = (data, id) => {
  return new Promise((resolve, reject) => {
    Http.post(backendUrl + "swotresponses/upload", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};


export const getSwotAnalysis = (id) => {
  return new Promise((resolve, reject) => {
    Http.get(backendUrl + `swot_analysis?swotId=${id}`)
      .then((res) => {
        console.info(res);
        console.info(res.data);
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getSwotGraph = (id) => {
  const url =
    id !== undefined ? `getAnalyticData?department_id=${id}` : 'getAnalyticData';
  return new Promise((resolve, reject) => {
    Http.get(backendUrl + url)
      .then((res) => {
        console.info(res);
        console.info(res.data);
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};