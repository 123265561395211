import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const SwotAnalysisBarChart = (data) => {
    const options = {
      chart: {
        type: "column",
        width: 700,
      },
      title: {
        text: "SWOT Analysis",
      },
      xAxis: {
        categories: ["Opportunities", "Strengths", "Weaknesses", "Threats"],
        title: {
          text: "Categories",
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: "%",
        },
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          name: "SWOT",
          data: [
            { y: parseInt(data?.data?.strengths), color: "#008B38" },
            { y: parseInt(data?.data?.opportunities), color: "#09074D" },
            { y: parseInt(data?.data?.weaknesses), color: "#FE8718" },
            { y: parseInt(data?.data?.threats), color: "#DE264D" },
          ],
          pointWidth: 50,
        },
      ],
    };

    return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default SwotAnalysisBarChart;
