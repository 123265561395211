import React, { useEffect, useState } from "react";
import { Table, theme } from "antd";
import * as SwotService from "../../services/SwotService";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";

const EmployeeSwotResponseList = () => {
    const [loader, setLoader] = useState(true);
    const [swotResponse, setResponse] = useState([]);
    const { state } = useLocation();
    const employeeId = state?.employeeId;

    const getSwotResponse = (employeeId) => {
        SwotService.getSwotResponseByEmoloyeeID(employeeId)
            .then((response) => {
                setResponse(response.swot_responses);
                setLoader(false);
            })
            .catch((err) => {
                let message = err.response.data.error;
                Swal.fire({
                    title: "Oops...",
                    text: message,
                    icon: "error",
                });
                console.log({ err });
            });
    };
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    const columns = [
        {
            title: "Swot name",
            dataIndex: "swot_name",
            key: "swot_name",
        },
        {
            title: "strengths",
            dataIndex: "strengths",
            key: "strengths",
        },
        {
            title: "weaknesses",
            dataIndex: "weaknesses",
            key: "weaknesses",
        },
        {
            title: "opportunities",
            dataIndex: "opportunities",
            key: "opportunities",
        },
        {
            title: "threats",
            dataIndex: "threats",
            key: "threats",
        },
        {
            title: "Created",
            dataIndex: "created_at",
            key: "created_at",
        },
    ];

    useEffect(() => {
        if (employeeId) {
            getSwotResponse(employeeId);
        }
    }, [employeeId]);
    return (
        <>
            <div
                style={{
                    padding: 24,
                    textAlign: "center",
                    background: colorBgContainer,
                    borderRadius: borderRadiusLG,
                }}
            >
                <div className="createComponent">
                    <h5>Swot Response</h5>
                </div>
            </div>
            <Table
                dataSource={swotResponse}
                columns={columns}
                pagination={false}
                rowKey="id"
                loading={loader}
            />
        </>
    );
};

export default EmployeeSwotResponseList;
