import React from "react";
import { Button, Form, Input, Checkbox } from "antd";
import { useNavigate, Link } from "react-router-dom";
import Swal from "sweetalert2";
import * as AuthService from "../services/AuthService";
import Logo from '../assets/images/logo.jpg';

const Login = () => {
    const navigate = useNavigate();

    const onFinish = (values) => {
        AuthService.loginCompany(values)
            .then((response) => {
                localStorage.setItem("access_token", response.token);
                console.info(response);
                window.location.reload();
                navigate("/dashboard");
            })
            .catch((err) => {
                let message = err.response.data.error;
                Swal.fire({
                    title: "Oops...",
                    text: message,
                    icon: "error",
                });
                console.log({ err });
            });
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    const onChange = (e) => {
        console.log(`checked = ${e.target.checked}`);
    };

    return (
        <div className="authWrapper">
            <div className="innerWrapper">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-5">
                            <div className="logoSection">
                                <img src={Logo} className="img-fluid" alt="logo" />
                            </div>
                            <div className="card authCard">
                                <div className="card-body">
                                    <div className="formWrapper">
                                        <div className="headingWrapper">
                                            <h2 className="heading">Login</h2>
                                            <p className="subText">Harness the power of SWOT analysis to strategically assess your strengths, weaknesses, opportunities, and threats.</p>
                                        </div>
                                        <Form
                                            className="formMain authForm"
                                            name="basic"
                                            title="Login"
                                            initialValues={{
                                                remember: true,
                                            }}
                                            onFinish={onFinish}
                                            onFinishFailed={onFinishFailed}
                                            autoComplete="off"
                                            layout="vertical"
                                        >
                                            <Form.Item
                                                className="formContent"
                                                label="Email"
                                                name="admin_email"
                                                rules={[
                                                    {
                                                        type:"email",
                                                        required: true,
                                                        message: "Please input your email!",
                                                    },
                                                ]}
                                            >
                                                <Input className="inpt formControl" />
                                            </Form.Item>
                                            <Form.Item
                                                className="formContent mb-3"
                                                label="Password"
                                                name="password"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please input your password!",
                                                    },
                                                ]}
                                            >
                                                <Input.Password className="inpt formControl" />
                                            </Form.Item>
                                            <div className="forgotRow mb-4">
                                                <Checkbox onChange={onChange} className="formCheck">Remember me</Checkbox>
                                                <Link to={'/forgot-password'} className="forgotLink">Forgot password?</Link>
                                            </div>
                                            <div className="text-center">
                                                <Button className="btnPrimary w-50" type="primary" htmlType="submit">
                                                    Login
                                                </Button>
                                            </div>
                                            <Form.Item className="bottomText">
                                                Don't have an account? <Link to="/signUp" className="bottomTextLink">Sign Up</Link>
                                            </Form.Item>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Login;
