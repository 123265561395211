import React, { useEffect, useState } from "react";
import { Table, Card } from "antd";
import * as SwotService from "../../services/SwotService";
import Swal from "sweetalert2";
import { useLocation, useNavigate } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";

const SwotResponseList = () => {
    const [loader, setLoader] = useState(true);
    const [swotResponse, setResponse] = useState([]);
    const { state } = useLocation();
    const swotId = state?.data?.swot_id;
    const swot = state?.data;
    console.info("oooo", swot);
    const navigate = useNavigate();

    const getSwots = (swotId) => {
        SwotService.getSwotResponseBySwotId(swotId)
            .then((response) => {
                setResponse(response?.responses);
                setLoader(false);
            })
            .catch((err) => {
                let message = err.response.data.error;
                Swal.fire({
                    title: "Oops...",
                    text: message,
                    icon: "error",
                });
                console.log({ err });
            });
    };

    const columns = [
        {
            title: "S.No.",
            dataIndex: "serial",
            key: "serial",
            render: (text, record, index) => index + 1,
        },
        {
            title: "Strengths",
            dataIndex: "strengths",
            key: "strengths",
            render: (text, record) => <div className="strenthsTableRow">{text}</div>,
        },
        {
            title: "Weaknesses",
            dataIndex: "weaknesses",
            key: "weaknesses",
        },
        {
            title: "Opportunities",
            dataIndex: "opportunities",
            key: "opportunities",
        },
        {
            title: "Threats",
            dataIndex: "threats",
            key: "threats",
        },
    ];

    useEffect(() => {
        if (swotId) {
            getSwots(swotId);
        }
    }, [swotId]);

    return (
        <div className="mainWrapper">
            <Card className="card contentCard border-0 mb-4">
                <div className="row">
                    <div className="col-md-12 mb-4">
                        <div className="pageHeader d-flex align-items-center analysisPageHeader">
                            <button
                                type="button"
                                className="backIcon btnOutline mb-4 me-4"
                                onClick={() => navigate(-1)}
                            >
                                <IoMdArrowRoundBack />
                                Back
                            </button>
                            <h3>SWOT Response</h3>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 mb-4">
                        <div className="innerCol colFirst">
                            <p className="swotLabel">SWOT Name</p>
                            <h4>{swot?.swot_name}</h4>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 mb-4">
                        <div className="innerCol colSecond">
                            <p className="swotLabel">Sent To</p>
                            <h4>{swot.recipient_count}</h4>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 mb-4">
                        <div className="innerCol colThird">
                            <p className="swotLabel">Filled By</p>
                            <h4>{swot.response_count}</h4>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="innerCol colFourth">
                            <p className="swotLabel">SWOT Percentage</p>
                            <h4>
                                {((swot.response_count / swot.recipient_count) * 100).toFixed(
                                    2
                                )}{" "}
                                %
                            </h4>
                        </div>
                    </div>
                    <div className="col-md-12 mt-5">
                        <Table
                            className="swotTable"
                            dataSource={swotResponse}
                            columns={columns}
                            pagination={{
                                showSizeChanger: true,
                            }}
                            rowKey="id"
                            loading={loader}
                            bordered
                        />
                    </div>
                </div>
            </Card>
        </div>
    );
};

export default SwotResponseList;
