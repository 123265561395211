import Http from "../Http";
import { backendUrl } from "../helper";

export const createEmployee = (data) => {
  return new Promise((resolve, reject) => {
    Http.post(backendUrl + "employees", data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getEmployees = () => {
  return new Promise((resolve, reject) => {
    Http.get(backendUrl + "employees")
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteEmployee = (id) => {
  return new Promise((resolve, reject) => {
    Http.delete(backendUrl + `employees/${id}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateEmployee = (data,id) => {
  return new Promise((resolve, reject) => {
    Http.put(backendUrl + `employees/${id}`, data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const createEmployeeCSV = (data,id) => {
  return new Promise((resolve, reject) => {
    Http.post(backendUrl + "ImportEmployee", data,{
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

