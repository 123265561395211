import React, { useEffect, useState, useRef } from "react";
import * as SwotService from "../../services/SwotService";
import Swal from "sweetalert2";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, Spin, Button } from "antd";
import SwotAnalysisBarChart from "../../Helpers/SwotAnalysisBarChart";
import { IoMdArrowRoundBack } from "react-icons/io";
import html2pdf from "html2pdf.js";

const SwotAnalysis = () => {
    const [loader, setLoader] = useState(true);
    const [analizedData, setanalizedData] = useState({});
    const { state } = useLocation();
    const swot = state?.data;
    const swotId = state?.data?.swot_id;
    const navigate = useNavigate();
    const pdfContentRef = useRef(null);

    const analyseSwots = (swotId) => {
        SwotService.getSwotAnalysis(swotId)
            .then((response) => {
                setanalizedData(JSON.parse(response));
                setLoader(false);
            })
            .catch((err) => {
                let message = err.response.data.error;
                Swal.fire({
                    title: "Oops...",
                    text: message,
                    icon: "error",
                });
                console.log({ err });
            });
    };

    useEffect(() => {
        if (swotId) {
            analyseSwots(swotId);
        }
    }, [swotId]);

    const formatKey = (key) => {
        return key
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    const generatePdf = () => {
        const element = pdfContentRef.current;
        const options = {
            margin: [0.1, 0.1],
            filename: 'swot-analysis.pdf',
            image: { type: 'jpeg', quality: 1 },
            html2canvas: { scale: 2, logging: true },
            jsPDF: { unit: 'pt', format: 'letter', orientation: 'portrait' },
            pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
        };
        setTimeout(() => {
            html2pdf().set(options).from(element).save();
        }, 100);
    };

    return (
        <>
            {loader ? (
                <div className="spinner">
                    <Spin />
                </div>
            ) : (
                <div className="mainWrapper">
                    <Card className="card contentCard border-0 mb-4">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="pageHeader d-flex align-items-start justify-content-between analysisPageHeader">
                                    <div className="leftSection d-flex align-items-center">
                                        <button
                                            type="button"
                                            className="backIcon btnOutline me-4"
                                            onClick={() => navigate(-1)}
                                        >
                                            <IoMdArrowRoundBack />
                                            Back
                                        </button>
                                        <h3>SWOT Analysis</h3>
                                    </div>
                                    <div className="rightSection">
                                        <Button onClick={generatePdf} type="primary">
                                            Download PDF
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>
                    <Card className="card contentCard border-0" ref={pdfContentRef} id="pdfContent">
                        <div className="row">
                            <div className="col-lg-3 col-md-6 mb-4">
                                <div className="innerCol colFirst">
                                    <p className="swotLabel">SWOT Name</p>
                                    <h4>{swot?.swot_name}</h4>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 mb-4">
                                <div className="innerCol colSecond">
                                    <p className="swotLabel">Sent To</p>
                                    <h4>{swot?.recipient_count}</h4>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 mb-4">
                                <div className="innerCol colThird">
                                    <p className="swotLabel">Filled By</p>
                                    <h4>{swot?.response_count}</h4>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="innerCol colFourth">
                                    <p className="swotLabel">SWOT Percentage</p>
                                    <h4>
                                        {(
                                            (swot.response_count / swot.recipient_count) *
                                            100
                                        ).toFixed(2)}{" "}
                                        %
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 mb-4">
                                <Card title="Summary" className="">
                                    {analizedData?.summary}
                                </Card>
                            </div>
                            <div className="col-md-12 mb-4">
                                <Card title="Analysis" className="innerCard">
                                    <div className="row">
                                        {Object.keys(analizedData?.analysis).map((key) => (
                                            <div className="col-md-6" key={key}>
                                                <h5>{key.charAt(0).toUpperCase() + key.slice(1)}</h5>
                                                <ul>
                                                    {analizedData?.analysis[key].map((item, index) => (
                                                        <li key={index}>{item}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        ))}
                                    </div>
                                </Card>
                            </div>
                            <div class="html2pdf__page-break"></div>
                            <div className="col-md-12 mt-4 mb-4">
                                <Card title="Analytic Data" className="innerCard">
                                    <SwotAnalysisBarChart data={analizedData?.analytic} />
                                </Card>
                            </div>
                            <div className="col-md-12 mb-4">
                                <Card title="Solutions" className="innerCard">
                                    {Object.keys(analizedData?.solutions).map((key) => (
                                        <div key={key}>
                                            <h5>{formatKey(key)}</h5>
                                            <ul>
                                                {analizedData?.solutions[key].map((item, index) => (
                                                    <li key={index}>{item}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    ))}
                                </Card>
                            </div>
                            <div class="html2pdf__page-break"></div>
                            <div className="col-md-12 mb-4 mt-4">
                                <Card title="Suggestions" className="innerCard">
                                    {analizedData?.suggestions}
                                </Card>
                            </div>
                            <div className="col-md-12 mb-4 mt-4">
                                <Card title="Critical Analysis" className="innerCard">
                                    {Object.keys(analizedData?.critical_analysis).map((key) => (
                                        <div key={key}>
                                            <h5>{formatKey(key)}</h5>
                                            <ul>
                                                {analizedData?.critical_analysis[key].map(
                                                    (item, index) => (
                                                        <li key={index}>{item}</li>
                                                    )
                                                )}
                                            </ul>
                                        </div>
                                    ))}
                                </Card>
                            </div>
                            <div className="col-md-12 mb-4">
                                <Card title="Goals" className="innerCard">
                                    {Object.keys(analizedData?.goals).map((key) => (
                                        <div key={key}>
                                            <h5>{formatKey(key)}</h5>
                                            <ul>
                                                {analizedData?.goals[key].map((item, index) => (
                                                    <li key={index}>{item}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    ))}
                                </Card>
                            </div>
                        </div>
                    </Card>
                </div>
            )}
        </>
    );
};
export default SwotAnalysis;