import React, { useState, useEffect } from "react";
import { Form, Input, Button, Select, Card } from "antd";
import Swal from "sweetalert2";
import * as Employee from "../../services/Employee";
import * as Department from "../../services/Department";
import { useLocation, useNavigate } from "react-router-dom";
import "../../styles/employee.css";

const { Option } = Select;
const CreateEmployee = () => {
    const [form] = Form.useForm();
    let navigate = useNavigate();
    const { state } = useLocation();
    const [departments, setDepartments] = useState([]);
    const [selecteddepartment, setSelectedDepartment] = useState(
        state?.department
    );
    const employee = state?.employee ? state?.employee : {};

    const handleDepartmentChange = (value) => {
        setSelectedDepartment(value);
    };

    useEffect(() => {
        Department.getDepartments()
            .then((response) => {
                setDepartments(response?.data);
            })
            .catch((err) => {
                let message = err.response.data.error;
                Swal.fire({
                    title: "Oops...",
                    text: message,
                    icon: "error",
                });
                console.log({ err });
            });
    }, []);

    const onFinish = (values) => {
        if (state?.employee) {
            Employee.updateEmployee(values, state?.employee.id)
                .then((response) => {
                    Swal.fire({
                        title: "Employee Updated",
                        icon: "success",
                    }).then(function () {
                        navigate("/employees");
                    });
                })
                .catch((err) => {
                    let message = err.response.data.error;
                    Swal.fire({
                        title: "Oops...",
                        text: message,
                        icon: "error",
                    });
                    console.log({ err });
                });
        } else {
            Employee.createEmployee(values)
                .then((response) => {
                    Swal.fire({
                        title: "Employee Created",
                        icon: "success",
                    }).then(function () {
                        navigate("/employees");
                    });
                })
                .catch((err) => {
                    let message = err.response.data.error;
                    Swal.fire({
                        title: "Oops...",
                        text: message,
                        icon: "error",
                    }).then(function () {
                        navigate("/employees");
                    });
                    console.log({ err });
                });
        }

        form.resetFields(); // Reset the form fields after submission
    };

    return (
        <div className="employeeWrapper">
            <div className="formheader">
                <h3>Employee Form</h3>
            </div>
            <Card className="employeeFormCard">
                <Form
                    form={form}
                    // labelCol={{ span: 8 }}
                    // wrapperCol={{ span: 16 }}
                    onFinish={onFinish}
                >
                    <label>Full Name</label>
                    <Form.Item
                        name="full_name"
                        initialValue={employee.full_name ? employee.full_name : ""}
                        rules={[
                            { required: true, message: "Please input your full name!" },
                        ]}
                    >
                        <Input placeholder="Enter Employee Full name" />
                    </Form.Item>
                    <label>Email</label>
                    <Form.Item
                        name="email"
                        initialValue={employee.email ? employee.email : ""}
                        rules={[
                            {
                                required: true,
                                type: "email",
                                message: "Please input a valid email address!",
                            },
                        ]}
                    >
                        <Input placeholder="Enter Employee Email" />
                    </Form.Item>
                    <label>Mobile</label>
                    <Form.Item
                        name="mobile"
                        initialValue={employee.mobile ? employee.mobile : ""}
                        rules={[
                            {
                                required: true,
                                message: "Please input your mobile number!",
                            },
                        ]}
                    >
                        <Input
                            type="number"
                            min={1}
                            minLength={10}
                            placeholder="Enter Employee Mobile"
                        />
                    </Form.Item>
                    <label>Select Department</label>
                    <Form.Item name="departments" initialValue={selecteddepartment}>
                        <Select
                            style={{ width: "100%" }}
                            placeholder="Select Employee Department"
                            onChange={handleDepartmentChange}
                        >
                            {departments?.map((department) => {
                                return (
                                    <Option key={department.id} value={department.id}>
                                        {department.name}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                    <div className="submitSection">
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </div>
                </Form>
            </Card>
        </div>
    );
};

export default CreateEmployee;
