import React, { useState, useEffect } from "react";
import { Button, Form, Input, Select, Upload, Spin } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import * as AuthService from "../services/AuthService";
import Logo from '../assets/images/logo.jpg';

const SignUp = () => {
	const navigate = useNavigate();
	const [fileList, setFileList] = useState([]);
	const [countries, setCountries] = useState([]);
	const [cities, setCities] = useState([]);
	const [states, setStates] = useState([]);
	const [timeZones, setTimeZones] = useState([]);
	const [countryCode, setCountryCode] = useState("");
	const [form] = Form.useForm();

	const handleCountryChange = (value, option) => {
		setStates([]);
		form.setFieldsValue({ state: "" });
		form.setFieldsValue({ city: "" });
		form.setFieldsValue({ timezone: "" });
		setCountryCode(option.key);
		getState(option.key);
		getTimeZone(option.key);
	};

	const handleStateChange = (value, option) => {
		getCity(option.key, countryCode);
		form.setFieldsValue({ city: "" });
	};

	const { Option } = Select;

	const onFinish = (values) => {
		const formData = new FormData();
		Object.entries(values).map(([key, value]) => {
			if (key === "logo") {
				formData.append(key, value.file);
			} else {
				formData.append(key, value);
			}
		});
		AuthService.registerCompany(formData)
			.then((response) => {
				Swal.fire({
					title: "Success",
					text: "Account Created",
					icon: "success",
				});
				navigate("/");
			})
			.catch((err) => {
				let message = err.response?.data?.message;
				Swal.fire({
					title: "Oops...",
					text: message,
					icon: "error",
				});
				console.log({ err });
			});
	};

	const onFinishFailed = (errorInfo) => {
		console.log("Failed:", errorInfo);
	};

	const getCountry = () => {
		AuthService.getCountry()
			.then((response) => {
				setCountries(response);
			})
			.catch((err) => {
				let message = err.response.data.error;
				Swal.fire({
					title: "Oops...",
					text: message,
					icon: "error",
				});
				console.log({ err });
			});
	};

	const getState = (country_code) => {
		AuthService.getState(country_code)
			.then((response) => {
				setStates(response);
			})
			.catch((err) => {
				let message = err.response.data.error;
				Swal.fire({
					title: "Oops...",
					text: message,
					icon: "error",
				});
				console.log({ err });
			});
	};

	const getCity = (state_code, country_code) => {
		AuthService.getCity(state_code, country_code)
			.then((response) => {
				setCities(response);
			})
			.catch((err) => {
				let message = err.response?.data?.error;
				Swal.fire({
					title: "Oops...",
					text: message,
					icon: "error",
				});
				console.log({ err });
			});
	};

	const getTimeZone = (country_code) => {
		AuthService.getTimeZone(country_code)
			.then((response) => {
				setTimeZones(response);
			})
			.catch((err) => {
				let message = err.response.data.error;
				Swal.fire({
					title: "Oops...",
					text: message,
					icon: "error",
				});
				console.log({ err });
			});
	};

	useEffect(() => {
		getCountry();
	}, []);

	const props = {
		beforeUpload: (file) => {
			if (file.size <= 2000000) {
				if (fileList < 1) {
					setFileList([...fileList, file]);
					return false;
				} else {
					console.log("err");
				}
			} else {
				console.log("err");
			}
		},
		fileList,

		// onChange(info) {
		//     if (info.file.status !== 'uploading') {
		//         console.log(info.file, info.fileList);
		//     }
		//     if (info.file.status === 'done') {
		//         message.success(`${info.file.name} file uploaded successfully`);
		//     } else if (info.file.status === 'error') {
		//         message.error(`${info.file.name} file upload failed.`);
		//     }
		// },
	};

	return (
		<div className="authWrapper">
			<div className="innerWrapper">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-md-10">
							<div className="logoSection">
                                <img src={Logo} className="img-fluid" alt="logo" />
                            </div>
							<div className="card authCard">
								<div className="card-body">
									<div className="formWrapper">
										<div className="headingWrapper">
											<h2 className="heading">Sign Up</h2>
											<p className="subText">
												Harness the power of SWOT analysis to strategically
												assess your strengths, weaknesses, opportunities, and
												threats.
											</p>
										</div>
										<Form
											form={form}
											className="formMain authForm"
											name="basic"
											title="Register"
											initialValues={{
												remember: true,
											}}
											onFinish={onFinish}
											onFinishFailed={onFinishFailed}
											autoComplete="off"
											layout="vertical"
										>
											<div className="row">
												<div className="col-md-3">
													<Form.Item
														className="formContent"
														label="Company Name"
														name="company_name"
														rules={[
															{
																required: true,
																message: "Please input your company_name!",
															},
														]}
													>
														<Input
															className="formControl"
															placeholder="Company Name"
														/>
													</Form.Item>
												</div>
												<div className="col-md-6">
													<Form.Item
														className="formContent"
														label="Company Address"
														name="company_address"
														rules={[
															{
																required: true,
																message: "Please input your company address!",
															},
														]}
													>
														<Input
															className="formControl"
															placeholder="Company Address"
														/>
													</Form.Item>
												</div>
												<div className="col-md-3">
													<Form.Item
														name="country"
														label="Country"
														rules={[
															{
																required: true,
																message: "Please select an Country!",
															},
														]}
													>
														<Select
															style={{ width: "100%" }}
															className="formSelect"
															placeholder="Select country"
															onChange={handleCountryChange}
															showSearch={true}
														>
															{countries.length > 0 ? (
																countries?.map((country) => {
																	return (
																		<Option
																			key={country.country_code}
																			value={country.country_name}
																		>
																			{country.country_name}
																		</Option>
																	);
																})
															) : (
																<div
																	style={{
																		display: "flex",
																		justifyContent: "center",
																		alignItems: "center",
																	}}
																>
																	<Spin />
																</div>
															)}
														</Select>
													</Form.Item>
												</div>
												<div className="col-md-3">
													<Form.Item
														name="state"
														label="State"
														rules={[
															{
																required: true,
																message: "Please select an State!",
															},
														]}
													>
														<Select
															style={{ width: "100%" }}
															className="formSelect"
															placeholder="Select state"
															onChange={handleStateChange}
															showSearch={true}
														>
															{states.length > 0 ? (
																states?.map((state) => {
																	return (
																		<Option
																			key={state.state_code}
																			value={state.state_name}
																		>
																			{state.state_name}
																		</Option>
																	);
																})
															) : (
																<div
																	style={{
																		display: "flex",
																		justifyContent: "center",
																		alignItems: "center",
																		width: "100%",
																	}}
																>
																	<Spin />
																</div>
															)}
														</Select>
													</Form.Item>
												</div>
												<div className="col-md-3">
													<Form.Item
														name="city"
														label="City"
														rules={[
															{
																required: true,
																message: "Please select an City!",
															},
														]}
													>
														<Select
															style={{ width: "100%" }}
															className="formSelect"
															placeholder="Select city"
															showSearch={true}
														>
															{cities.length > 0 ? (
																cities?.map((city) => {
																	return (
																		<Option
																			key={city.city_code}
																			value={city.city_name}
																		>
																			{city.city_name}
																		</Option>
																	);
																})
															) : (
																<div
																	style={{
																		display: "flex",
																		justifyContent: "center",
																		alignItems: "center",
																		width: "100%",
																	}}
																>
																	<Spin />
																</div>
															)}
														</Select>
													</Form.Item>
												</div>
												<div className="col-md-3">
													<Form.Item
														name="timezone"
														label="Timezone"
														rules={[
															{
																required: true,
																message: "Please select an Timezone!",
															},
														]}
													>
														<Select
															style={{ width: "100%" }}
															className="formSelect"
															placeholder="Select Timezone"
														>
															{timeZones?.map((time_zone) => {
																return (
																	<Option
																		key={time_zone.id}
																		value={time_zone.time_zone}
																	>
																		{time_zone.time_zone}
																	</Option>
																);
															})}
														</Select>
													</Form.Item>
												</div>
												<div className="col-md-3">
													<Form.Item
														label="Zip Code"
														className="formContent"
														name="zip"
														rules={[
															{
																required: true,
																message: "Please input your zip code!",
															},
														]}
													>
														<Input
															type="number"
															min={1}
															className="formControl"
															placeholder="Zip Code"
														/>
													</Form.Item>
												</div>
												<div className="col-md-3">
													<Form.Item
														label="Industry"
														className="formContent"
														name="industry"
														rules={[
															{
																required: true,
																message: "Please input your Industry!",
															},
														]}
													>
														<Input
															min={1}
															className="formControl"
															placeholder="Industry"
														/>
													</Form.Item>
												</div>
											</div>

											<hr style={{ marginTop: 0, borderColor: "#666666" }} />
											<div className="row">
												<div className="col-md-3">
													<Form.Item
														className="formContent"
														label="Admin Name"
														name="admin_name"
														rules={[
															{
																required: true,
																message: "Please input your admin_name!",
															},
														]}
													>
														<Input
															className="formControl"
															placeholder="Enter Name"
														/>
													</Form.Item>
												</div>
												<div className="col-md-3">
													<Form.Item
														className="formContent"
														label="Admin Email"
														name="admin_email"
														rules={[
															{
																type: "email",
																required: true,
																message: "Please input your admin email!",
															},
														]}
													>
														<Input
															className="formControl"
															placeholder="Enter email here"
														/>
													</Form.Item>
												</div>
												<div className="col-md-3">
													<Form.Item
														className="formContent"
														label="Contact Number"
														name="contact_number"
														rules={[
															{
																required: true,
																message: "Please input your contact_number!",
															},
														]}
													>
														<Input
															type="number"
															className="formControl"
															placeholder="Enter contact number"
														/>
													</Form.Item>
												</div>
												<div className="col-md-3">
													<Form.Item
														label="Number of Staff"
														className="formContent"
														name="staff_size"
														rules={[
															{
																required: true,
																message: "Please input staff_size!",
															},
														]}
													>
														<Input
															type="number"
															min={1}
															className="formControl"
															placeholder="Enter number of staff here"
														/>
													</Form.Item>
												</div>
												<div className="col-md-3">
													<Form.Item
														label="Password"
														className="formContent"
														name="password"
														rules={[
															{
																required: true,
																message: "Please input your password!",
															},
															{
																min: 6,
																message:
																	"Password must be at least 6 characters long!",
															},
															({ getFieldValue }) => ({
																validator(_, value) {
																	if (
																		!value ||
																		/[!@#$%^&*(),.?":{}|<>]/.test(value)
																	) {
																		return Promise.resolve();
																	}
																	return Promise.reject(
																		new Error(
																			"Password must contain at least one special character!"
																		)
																	);
																},
															}),
														]}
													>
														<Input.Password
															className="formControl"
															placeholder="Enter password here"
														/>
													</Form.Item>
												</div>
												<div className="col-md-3">
													<Form.Item
														label="Confirm Password"
														className="formContent"
														name="confirm_password"
														rules={[
															{
																required: true,
																message: "Please confirm your password!",
															},

															({ getFieldValue }) => ({
																validator(_, value) {
																	if (
																		!value ||
																		getFieldValue("password") === value
																	) {
																		return Promise.resolve();
																	}
																	return Promise.reject(
																		new Error(
																			"The new password that you entered do not match!"
																		)
																	);
																},
															}),
														]}
													>
														<Input.Password
															className="formControl"
															placeholder="Enter confirm password here"
														/>
													</Form.Item>
												</div>
												<div className="col-md-3">
													<Form.Item
														name="logo"
														className="username"
														label="Add Logo"
													>
														<Upload
															{...props}
															accept="image/png, image/jpeg"
															maxCount={1}
															name="file"
															listType="picture"
														>
															<Button
																icon={<UploadOutlined />}
																className="uploadButton"
															>
																Select File
															</Button>
														</Upload>
													</Form.Item>
												</div>
												<div className="col-md-12 text-center">
													<div className="formContent">
														<Button
															className="btnPrimary w-30"
															type="primary"
															htmlType="submit"
														>
															Sign Up
														</Button>
													</div>
												</div>
												<div className="col-md-12 bottomText">
													Already have an account?{" "}
													<Link to="/" className="bottomTextLink">
														Login
													</Link>
												</div>
											</div>
										</Form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default SignUp;
