import React, { useEffect, useState } from "react";
import { Button, Form, Input, Row, Col, Card, Space, Image } from "antd";
import "../styles/myform.css";

import Swal from "sweetalert2";
import * as SwotService from "../services/SwotService";
import { backendStorgeURL } from "../helper";

const { TextArea } = Input;
const SwotResponse = () => {
    const queryParameters = new URLSearchParams(window.location.search);
    const [status, setStatus] = useState(true);
    const [loader, setLoader] = useState(true);
    const [scheduleSwot, setscheduleSwot] = useState({});
    const token = queryParameters.get("token");
    const [form] = Form.useForm();
    const formItems = [
        { label: "Strengths", name: "strengths", maxLength: 500 },
        { label: "Weaknesses", name: "weaknesses", maxLength: 500 },
        { label: "Opportunities", name: "opportunities", maxLength: 500 },
        { label: "Threats", name: "threats", maxLength: 500 },
    ];

    useEffect(() => {
        SwotService.checkSwotExpiration(token)
            .then((response) => {
                setscheduleSwot(response);
                setStatus(true);
                setLoader(false)
            })
            .catch((err) => {
                setStatus(false);
                setLoader(false)
                let message = err.response.data.message;
                Swal.fire({
                    title: "Oops...",
                    text: message,
                    icon: "info",
                });
                console.log({ err });

            });
    }, []);

    const onFinish = (values) => {
        SwotService.swotResponse(values, token)
            .then((response) => {
                Swal.fire({
                    title: "Swot Response Submited...",
                    icon: "success",
                });
                setStatus(false);
                setLoader(false)
            })
            .catch((err) => {
                let message = err.response.data.message;
                Swal.fire({
                    title: "Oops...",
                    text: message,
                    icon: "error",
                });
                console.log({ err });
                setStatus(false);
                setLoader(false)
            });
        // Reset the form fields after submission
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    const onReset = () => {
        form.resetFields();
    };

    return (
        <>
            {loader ? (
                ""
            ) : (
                <>
                    {status ? (
                        <div className="mainWrapper p-5 bg-light">
                            <div className="row">
                                <div className="col-md-12 mb-4">
                                    <Card
                                        title="SWOT"
                                        bordered={false}
                                        className="swotCard"
                                    >
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="logoWrapper">
                                                    <Image
                                                        src={backendStorgeURL + scheduleSwot.logo}
                                                        alt="Logo"
                                                        preview={false}
                                                        className="img-fluid"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-9">
                                                <div className="detailsWrap">
                                                    <div className="detailsItems">
                                                        <h5 className="detailsTitle">Swot Name</h5>
                                                        <p className="detailsData">{scheduleSwot?.swot_name}</p>
                                                    </div>
                                                    <div className="detailsItems">
                                                        <h5 className="detailsTitle">Manager Intro</h5>
                                                        <p className="detailsData">{scheduleSwot?.manager_intro}</p>
                                                    </div>
                                                    <div className="detailsItems">
                                                        <h5 className="detailsTitle">Manager Privacy</h5>
                                                        <p className="detailsData">{scheduleSwot?.manager_privacy}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                                <div className="col-md-12">
                                    <Form
                                        name="swotForm"
                                        form={form}
                                        onFinish={onFinish}
                                        onFinishFailed={onFinishFailed}
                                        autoComplete="off"
                                    >
                                        <Row gutter={[16, 16]}>
                                            {formItems.map((item, index) => (
                                                <Col md={12} key={index} className="swotItems">
                                                    <label> {item.label}</label>
                                                    <Form.Item
                                                        name={item.name}
                                                        rules={[
                                                            { required: true, message: "Please input" },
                                                        ]}
                                                    >
                                                        <TextArea
                                                            rows={6}
                                                            maxLength={item?.maxLength}
                                                            showCount
                                                            className="swotFillControl"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            ))}
                                        </Row>

                                        <Form.Item className="ContentWrapper text-center mt-5">
                                            <Space className="text-center">
                                                <Button
                                                    className="subBtn"
                                                    type="primary"
                                                    htmlType="submit"
                                                >
                                                    Submit
                                                </Button>
                                                <button htmlType="button" onClick={onReset} className="btnOutline">
                                                    Reset
                                                </button>
                                            </Space>
                                        </Form.Item>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="alreadySubmittedWrapper">
                            <Card>
                                <h5>You Already Submitted OR Link is Expired.</h5>
                            </Card>
                        </div>
                    )}
                </>
            )}
        </>
    );
};
export default SwotResponse;
