import Http from "../Http";
import { backendUrl } from "../helper";


export const createDepartmnet = (data) => {
  return new Promise((resolve, reject) => {
    Http.post(backendUrl + "departments", data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getDepartments = () => {
  return new Promise((resolve, reject) => {
    Http.get(backendUrl + "departments")
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteDepartment = (id) => {
  return new Promise((resolve, reject) => {
    Http.delete(backendUrl + `departments/${id}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateDepartment = (data,id) => {
  return new Promise((resolve, reject) => {
    Http.put(backendUrl + `departments/${id}`, data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const improtDepartmentCSV = (data, id) => {
  return new Promise((resolve, reject) => {
    Http.post(backendUrl + "ImportDepartment", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
