function SwotSurveyChart(data) {
    const months = data ? Object.keys(data) : [];

    // Extract swot and survey data for each month
    const swotData = months.map((month) => data[month].swot);
    const surveyData = months.map((month) => data[month].survey);

    const abbreviatedMonths = months.map((month) => {
        switch (month) {
            case "January":
                return "Jan";
            case "February":
                return "Feb"; // This is "Fev" as per the request, but "Feb" is usual
            case "March":
                return "Mar";
            case "April":
                return "Apr";
            case "May":
                return "May";
            case "June":
                return "Jun";
            case "July":
                return "Jul";
            case "August":
                return "Aug";
            case "September":
                return "Sep";
            case "October":
                return "Oct";
            case "November":
                return "Nov";
            case "December":
                return "Dec";
            default:
                return month;
        }
    });

    const options = {
        chart: {
            type: "areaspline",
            spacing: 20,
        },
        title: {
            text: "Total SWOT",
            align: "left",
            margin: 40,
        },
        subtitle: {
            text: "",
            align: "left",
        },
        legend: {
            layout: "vertical",
            align: "right",
            verticalAlign: "top",
            floating: true,
            borderWidth: 0,
            itemStyle: {
                color: "#001529",
                fontSize: "12px",
                fontWeight: "400",
                fontFamily: "Arial, sans-serif",
            },
        },
        xAxis: {
            categories: abbreviatedMonths,
            labels: {
                enabled: true,
                style: {
                    color: "#001529",
                    fontSize: "12px",
                    fontWeight: "400",
                    fontFamily: "Poppins",
                },
            },
            tickLength: 1,
            title: {
                text: null,
            },
        },
        yAxis: {
            title: {
                text: "",
            },
            labels: {
                formatter: function () {
                    return this.value;
                },
                style: {
                    color: "#001529",
                    fontSize: "12px",
                    fontWeight: "400",
                    fontFamily: "Poppins",
                },
            },
            min: 0,
            // max: 10,
            //   tickInterval: 20,
        },
        credits: {
            enabled: false,
        },
        plotOptions: {
            series: {
                borderRadius: 3,
                pointPadding: 0,
                groupPadding: 0.05,
            },
            areaspline: {
                fillOpacity: 0.5,
            },
        },
        series: [
            {
                name: "Total SWOT",
                color: "#001529",
                data: swotData,
            },
            {
                name: "Total Survey",
                color: "#001521",
                data: surveyData,
            },
        ],
    };
    return options;
}

function ResponseSurveyChart(data) {
    const months = data ? Object.keys(data) : [];
    const surveyData = months.map(
        (month) =>
            (data[month].surveyData.totalResponse /
                data[month].surveyData.totalEmployee) *
            100 || 0
    );
    const swotData = months.map(
        (month) =>
            (data[month].swotData.totalResponse /
                data[month].swotData.totalEmployee) *
            100 || 0
    );
    const abbreviatedMonths = months.map((month) => {
        switch (month) {
            case "January":
                return "Jan";
            case "February":
                return "Feb";
            case "March":
                return "Mar";
            case "April":
                return "Apr";
            case "May":
                return "May";
            case "June":
                return "Jun";
            case "July":
                return "Jul";
            case "August":
                return "Aug";
            case "September":
                return "Sep";
            case "October":
                return "Oct";
            case "November":
                return "Nov";
            case "December":
                return "Dec";
            default:
                return month;
        }
    });

    const options = {
        chart: {
            type: "areaspline",
            spacing: 20,
        },
        title: {
            text: "Total SWOT Response",
            align: "left",
            margin: 40,
        },
        subtitle: {
            text: "",
            align: "left",
        },
        legend: {
            layout: "vertical",
            align: "right",
            verticalAlign: "top",
            floating: true,
            itemStyle: {
                color: "#001529",
                fontSize: "12px",
                fontWeight: "400",
                fontFamily: "Arial, sans-serif",
            },
        },
        xAxis: {
            categories: abbreviatedMonths,
            plotBands: [
                {
                    from: 2019,
                    to: 2020,
                    color: "#001529",
                },
            ],
            labels: {
                enabled: true,
            },
            tickLength: 1,
            title: {
                text: null,
            },
        },
        yAxis: {
            title: {
                text: "",
            },
            labels: {
                formatter: function () {
                    return `${this.value}%`;
                },
                style: {
                    color: "#001529",
                    fontSize: "12px",
                    fontWeight: "400",
                    fontFamily: "Poppins",
                },
            },
            min: 0,
            max: 100,
            tickInterval: 20,
        },
        credits: {
            enabled: false,
        },
        plotOptions: {
            series: {
                borderRadius: 3,
                pointPadding: 0,
                groupPadding: 0.05,
            },
            areaspline: {
                fillOpacity: 0.5,
            },
        },
        tooltip: {
            formatter: function () {
                return `<b>${this.series.name}</b><br/>${this.x}: ${this.y}%`;
            },
        },
        series: [
            {
                name: "Total Response",
                color: "#001529",
                data: swotData,
            },
            {
                name: "Total Survey Response",
                color: "#001529",
                data: surveyData,
            },
        ],
    };
    return options;
}

export { SwotSurveyChart, ResponseSurveyChart };
