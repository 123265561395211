import Http from "../Http";
import { backendUrl } from "../helper";

export const pingAPI = async () => {
  Http.get(backendUrl + "ping")
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const registerCompany = (data) => {
  return new Promise((resolve, reject) => {
    Http.post(backendUrl + "register_company", data,{
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const loginCompany = (data) => {
  return new Promise((resolve, reject) => {
    Http.post(backendUrl + "login_company", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const verifyEmail = async (token) => {
  Http.get(backendUrl + `verify_email?token=${token}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const getCountry = async () => {
  return new Promise((resolve, reject) => {
   
    Http.get(backendUrl + "countries")
      .then((res) => {
        resolve(res.data?.countries);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getState = async (country_code) => {
  return new Promise((resolve, reject) => {
    Http.get(backendUrl + `states?country_code=${country_code}`)
      .then((res) => {
        resolve(res.data?.states);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getCity = async (state_code,country_code) => {
  return new Promise((resolve, reject) => {
    Http.get(backendUrl + `cities?state_code=${state_code}&country_code=${country_code}`)
      .then((res) => {
        resolve(res.data?.cities);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getTimeZone = async (country_code) => {
  return new Promise((resolve, reject) => {
    Http.get(backendUrl + `time_zones?country_code=${country_code}`)
      .then((res) => {
        resolve(res.data?.timezones);
      })
      .catch((err) => {
        reject(err);
      });
  });
};


export const getCompany = async () => {
  return new Promise((resolve, reject) => {
    Http.get(backendUrl + `companies`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateCompany = (data,id) => {
  return new Promise((resolve, reject) => {
    Http.put(backendUrl + `companies/${id}`, data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const forgotPassword = (data) => {
  return new Promise((resolve, reject) => {
    Http.post(backendUrl + "forget_password", data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const resetPassword = (data,token) => {
  return new Promise((resolve, reject) => {
    Http.post(backendUrl + `reset_password?token=${token}`, data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const ChangePassword = (data) => {
  return new Promise((resolve, reject) => {
    Http.post(backendUrl + `resetCompanyPassword`, data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};