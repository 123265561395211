import React from "react";
import { Button, Form, Input } from "antd";
import * as AuthService from "../services/AuthService";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const ChangePassword = () => {
    const navigate = useNavigate();
    const onFinish = (values) => {
               AuthService.ChangePassword(values)
                 .then((response) => {
                   Swal.fire({
                     title: "Success",
                     text: "Password Changed",
                     icon: "success",
                   });
                localStorage.removeItem("access_token");
                   navigate("/");
                 })
                 .catch((err) => {
                   let message = err.response?.data?.message;
                   Swal.fire({
                     title: "Oops...",
                     text: message,
                     icon: "error",
                   });
                   console.log({ err });
                 });
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    return (
      <div className="authWrapper">
        <div className="innerWrapper d-flex justify-content-center align-items-center">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-5">
                <div className="card authCard">
                  <div className="card-body">
                    <div className="formWrapper">
                      <div className="headingWrapper">
                        <h2 className="heading">Change Password?</h2>
                        <p className="subText">
                          Stay protected. Change your password now.
                        </p>
                      </div>
                      <Form
                        className="formMain authForm"
                        name="basic"
                        title="Login"
                        initialValues={{
                          remember: true,
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        layout="vertical"
                      >
                        <Form.Item
                          className="formContent"
                          label="Old Password"
                          name="old_password"
                          rules={[
                            {
                              required: true,
                              message: "Please input your password!",
                            },
                          ]}
                        >
                          <Input.Password
                            className="formControl"
                            placeholder="Enter old password"
                          />
                        </Form.Item>
                        <Form.Item
                          name="new_password"
                          label="New Password"
                          rules={[
                            {
                              required: true,
                              message: "Please input your password!",
                            },
                          ]}
                          hasFeedback
                        >
                          <Input.Password
                            className="formControl"
                            placeholder="Enter new password"
                          />
                        </Form.Item>
                        <Form.Item
                          name="confirm"
                          label="Confirm Password"
                          dependencies={["new_password"]}
                          hasFeedback
                          rules={[
                            {
                              required: true,
                              message: "Please confirm your password!",
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (
                                  !value ||
                                  getFieldValue("new_password") === value
                                ) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(
                                  new Error(
                                    "The new password that you entered do not match!"
                                  )
                                );
                              },
                            }),
                          ]}
                        >
                          <Input.Password
                            className="formControl"
                            placeholder="Confirm new password"
                          />
                        </Form.Item>
                        <div className="text-center">
                          <Button
                            className="btnPrimary w-50"
                            type="primary"
                            htmlType="submit"
                          >
                            Save Changes
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};
export default ChangePassword;
